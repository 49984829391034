// import { ApolloProvider } from "@apollo/client";
import React from "react";
import ReactDOM from "react-dom/client";
import ReactModal from "react-modal";
// import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import App from "./App";
import "./index.css";
// import client from "./lib/apollo";
import "./lib/i18n";
// import AuthProvider from "./modules/common/provider/AuthProvider";
import ScreenWidthProvider from "./modules/common/provider/ScreenWidthProvider";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ScreenWidthProvider>
      {/* <ApolloProvider client={client}>
        <App />
        <ToastContainer
          hideProgressBar
          autoClose={2000}
          position="top-center"
        />
      </ApolloProvider> */}
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "0.75rem",
        }}
      >
        <h1 style={{ textAlign: "center", marginTop: "0", marginBottom: "0" }}>
          지금까지 Medfriend를 이용해주신 모든 분들께 감사드리며
        </h1>
        <h1 style={{ textAlign: "center", marginTop: "0", marginBottom: "0" }}>
          2025년 1월 Oceanlife의 Ocecare 서비스로 찾아뵙겠습니다.
        </h1>
        <h1 style={{ textAlign: "center", marginTop: "0", marginBottom: "0" }}>
          감사합니다.
        </h1>
      </div>
    </ScreenWidthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
ReactModal.setAppElement("#root");
